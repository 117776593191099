import moment from 'moment'
export default ({ data }) => {
  return {
    title: '入住信息',
    form: [
      {
        type: 'textGroup',
        value: [
          {
            name: '入住人',
            key: 'concatName',
            display: data.concatName
          },
          {
            name: '手机号',
            key: 'concatPhone',
            display: data.concatPhone
          },
          {
            name: '入住时间',
            value: data.orderItemList ? moment(data.orderItemList[0].checkInTime).format('YYYY年MM月DD日') : '',
            display: data.orderItemList ? data.orderItemList[0].checkInTime : false
          },
          {
            name: '离店时间',
            value: data.orderItemList ? moment(data.orderItemList[0].leaveTime).format('YYYY年MM月DD日') : '',
            display: data.orderItemList ? data.orderItemList[0].leaveTime : false
          }
        ].filter(e => e.display)
      }
    ]
  }
}
