export default ({ data, type }) => {
  return {
    title: '基础',
    form: [
      {
        type: 'textGroup',
        labelCol: { span: 3 },
        wrapperCol: { span: 24 },
        value: [
          {
            name: '单   号',
            key: 'orderSn',
            display: data.orderSn
          },
          {
            name: '店铺名称',
            key: 'shopName',
            display: data.shopName
          },
          {
            name: '购买人',
            key: 'buyName',
            display: data.buyName
          },
          {
            name: '订单状态',
            value:
              {
                '0': '待付款',
                '1': type == 'hotel' ? '待入住' : '待使用',
                '2': '待发货',
                '3': '待签收',
                '4': '待评价',
                '5': '已完成',
                '6': '已关闭',
                '102': '待确认',
                '101': '出票中'
              }[data.status] || '',
            display: true
          },
          {
            name: '联系方式',
            key: 'buyPhone',
            display: data.buyPhone
          },
          {
            name: '支付方式',
            value: { '1': '支付宝', '2': '微信', '3': '农行支付', '4': '0元付' }[data.payType],
            display: data.payType !== null
          },
          {
            name: '订单备注',
            value: data.remark,
            display: data.remark,
            width: '100%'
          }
        ].filter(e => e.display)
      }
    ]
  }
}
