export default ({ data } = {}) => {
  return {
    title: '记录',
    form: [
      {
        type: 'textGroup',
        value: [
          {
            name: '支付时间',
            key: 'paymentTime',
            display: Number(data.status) > 0 && data.paymentTime
          },
          {
            name: '发货时间',
            key: 'deliveryTime',
            display: Number(data.status) >= 3 && data.deliveryTime
          },
          {
            name: '完成时间',
            key: 'updateTime',
            display: Number(data.status) == 5 && data.updateTime
          }
        ].filter(e => e.display)
      }
    ]
  }
}
