import apiTool from '@/command/apiTool'
import { getAction, putAction } from '@/command/netTool'
import { Message, Modal } from 'ant-design-vue'

let [selectedRowKeysArr, customOrderIds, customOrderItemIds] = [[], '', '']

function getTableColumns(data) {
  const columns = [].concat(data.userCodeVOList)
  return columns
}

/* 同步订单 */
function syncOrder(setHidden, success) {
  getAction(`/ticketOrder/orderSync?orderId=${customOrderIds}`).then((res) => {
    if (res.code == 200) {
      Message.success('操作成功')
      success()
      setHidden()
      selectedRowKeysArr = []
    } else {
      Message.error(res.msg)
    }
  })
}

/* 核销 */
function verification(setHidden, success) {
  if (typeof handleData() != 'boolean') {
    putAction('/ticketOrder/hx', handleData()).then((res) => {
      if (res.code == 200) {
        Message.success('操作成功')
        success()
        setHidden()
        selectedRowKeysArr = []
      } else {
        Message.error(res.msg)
      }
    })
  }
}

/* 取消核销 */
function unVerification(setHidden, success) {
  if (typeof handleData() != 'boolean') {
    putAction('/ticketOrder/cancelHx', handleData()).then((res) => {
      if (res.code == 200) {
        Message.success('操作成功')
        success()
        setHidden()
        selectedRowKeysArr = []
      } else {
        Message.error(res.msg)
      }
    })
  }
}

/* 退款 */
function refund(setHidden, success) {
  if (typeof handleData() != 'boolean') {
    putAction('/ticketOrder/refund', handleData()).then((res) => {
      if (res.code == 200) {
        if (res.data == '5') {
          Message.error('退款失败')
        } else {
          Message.success('操作成功')
          success()
          setHidden()
          selectedRowKeysArr = []
        }
      } else {
        Message.error(res.msg)
      }
    })
  }
}

/* 取消第三方票务关联 */
function cancelAssociated(setHidden, success) {
  getAction('/ticketOrder/disassociate?orderId=' + customOrderIds).then((res) => {
    if (res.code == 200) {
      Message.success('操作成功')
      success()
      setHidden()
      selectedRowKeysArr = []
    } else {
      Message.error(res.msg)
    }
  })
}

function handleData() {
  if (!selectedRowKeysArr.length) {
    Message.warning('请选择需要操作的核销码')
    return false
  } else {
    return {
      orderId: customOrderIds,
      orderItemId: customOrderItemIds,
      userCodeList: selectedRowKeysArr,
    }
  }
}

function handleButton(str) {
  let buttonArr = []
  if (process.env.NODE_ENV === 'development') {
    buttonArr = ['同步订单', '核销', '取消核销', '退款', '取消第三方票务关联']
  } else {
    buttonArr = apiTool.getButtonName()
  }
  return buttonArr.includes(str)
}

export default function () {
  const { data, customOrderId, customOrderItemId, setHidden, success } = arguments[0]
  customOrderIds = customOrderId
  customOrderItemIds = customOrderItemId
  return {
    class: 'order-table',
    form: [
      {
        display: true,
        type: 'table',
        wrapperCol: 24,
        dataSource: getTableColumns(data),
        showRowSelect: true,
        showPagination: false,
        selectedRowKeys: selectedRowKeysArr,
        rowKey: 'code',
        onAddCustom: (e) => {
          selectedRowKeysArr = e
        },
        columns: [
          {
            title: '核销码',
            type: 'lt-200',
            display: true,
            customRender: (text, records, index, h) => {
              return <div>核销码: {records.code}</div>
            },
          },
          {
            title: '分销商',
            dataIndex: 'thirdPlatform',
            type: 'ct-100',
            display: true,
            customRender: (text, records, index, h) => {
              return { 0: '自我游' }[records.thirdPlatform] || ''
            },
          },
          {
            title: '状态',
            dataIndex: 'status',
            type: 'ct-100',
            display: true,
            customRender: (text, records, index, h) => {
              return { 0: '待使用', 1: '已核销', 2: '已退款' }[records.status] || ''
            },
          },
          {
            title: '时间',
            display: true,
            dataIndex: 'updateTime',
          },
        ].filter((e) => e.display),
      },
      {
        display: data.thirdLinkStatus == '0' && handleButton('同步订单'),
        type: 'button',
        cols: 4,
        label: '同步订单',
        props: {
          type: 'primary',
          on: {
            click: () => {
              syncOrder(setHidden, success)
            },
          },
        },
      },
      {
        display: data.status == '1' && handleButton('核销'),
        type: 'button',
        cols: 4,
        label: '核销',
        props: {
          type: 'primary',
          on: {
            click: () => {
              verification(setHidden, success)
            },
          },
        },
      },
      {
        display: data.status == '1' && handleButton('取消核销'),
        type: 'button',
        cols: 4,
        label: '取消核销',
        props: {
          style: {
            background: '#f56c6c',
            color: '#ffffff',
          },
          on: {
            click: () => {
              unVerification(setHidden, success)
            },
          },
        },
      },
      {
        display: data.status == '1' && handleButton('退款'),
        type: 'button',
        cols: 4,
        label: '退款',
        props: {
          style: {
            background: '#f56c6c',
            color: '#ffffff',
          },
          on: {
            click: () => {
              refund(setHidden, success)
            },
          },
        },
      },
      {
        display: data.thirdLinkStatus == '0' && handleButton('取消第三方票务关联'),
        type: 'button',
        cols: 6,
        label: '取消第三方票务关联',
        props: {
          style: {
            background: '#f56c6c',
            color: '#ffffff',
          },
          on: {
            click: () => {
              Modal.confirm({
                title: '提示',
                content: '取消后将无法获取三方的核销及退款状态',
                okText: '确认',
                cancelText: '取消',
                onOk: () => {
                  cancelAssociated(setHidden, success)
                },
              })
            },
          },
        },
      },
      {
        display: data.thirdLinkStatus == '0' && handleButton('取消第三方票务关联'),
        type: 'text',
        cols: 24,
        styles: {
          fontSize: '10px',
          color: '#f56c6c',
          width: '110%',
        },
        value:
          '温馨提示：该订单为三方票务订单，退款后请到对应票务平台进行同步退款审核，否则造成的损失，由商家自己承担。',
      },
    ].filter((e) => e.display),
  }
}
